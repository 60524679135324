<template>
    <div>
        <header>
            <h1>VALID</h1>
        </header>
        <section class="section1">
            <h2>A gestão de datas de validade na sua mão</h2>
        </section>
        <section class="section2">
            <div class="loader"></div>
            <h3>Sincronizando</h3>
        </section>
        <footer>
            <router-link to="/">Cancelar</router-link>
            <span>Uma tecnologia oferecida por: <img src="../assets/img/logo-minus.png" alt="logo minus" /></span>
        </footer>
        <msg-error v-if="showAlert" @closeError="closeError" :mensagemError="msgE"></msg-error>
    </div>
</template>

<script>
import HTTP from '../http/http.js';
import MsgError from '../components/MsgError';
import axios from 'axios';

export default {
    components: {
        MsgError,
    },

    data() {
        return {
            msgE: 'Falha no Login, favor tentar novamente. Se o erro persistir, consulte o suporte.',
            showAlert: false,
        };
    },

    created() {
        let loja = sessionStorage.getItem('loja');
        let tipoUsuario = sessionStorage.getItem('tipoUsuario');
        let categoriaUsuario = sessionStorage.getItem('categoriaUsuario');
        let categorias = categoriaUsuario.replaceAll(', ', ',');

        if (tipoUsuario == 'gestor') {
            axios
                .all([HTTP.get(`${loja}/modulos`), HTTP.get(`${loja}/admin/precos/show`)])
                .then((responseArr) => {
                    let sugestaoAPI = responseArr[0].data;
                    let precosAPI = responseArr[1].data;
                    this.$store.dispatch('setSugestao', sugestaoAPI);
                    this.$store.dispatch('setPrecos', precosAPI);

                    this.$router.push({ name: 'pedidos' });
                })

                .catch((error) => {
                    this.$router.push({ name: 'login' });
                    this.showAlert = true;
                    console.log(error);
                });

            const d = new Date();
            let day = d.getDay();

            if (day == 1) {
                localStorage.removeItem('produtos');
            }

            let todosProdutos = JSON.parse(localStorage.getItem('produtos'));

            if (todosProdutos) {
                this.$store.dispatch('setTodosProdutos', todosProdutos);
            } else {
                HTTP.get(`${loja}/produtos-geral`).then((resp) => {
                    const todosProdutos = resp.data;
                    this.$store.dispatch('setTodosProdutos', todosProdutos);

                    try {
                        localStorage.setItem('produtos', JSON.stringify(todosProdutos));
                    } catch (e) {
                        console.log(e);
                    }
                });
            }
        } else {
            axios
                .all([
                    HTTP.get(`${loja}/admin/precos/${categorias}`),
                    HTTP.get(`${loja}/admin/precos/${categorias}/historico`),
                ])
                .then((responseArr) => {
                    let precosAPI = responseArr[0].data;
                    let historicoAPI = responseArr[1].data;

                    this.$store.dispatch('setPrecos', precosAPI);
                    this.$store.dispatch('setHistorico', historicoAPI);

                    this.$router.push({ name: 'menu.comercial', state: { tipoLogin: this.tipoLogin } });
                })
                .catch((error) => {
                    this.$router.push({ name: 'login' });
                    this.showAlert = true;
                    console.log(error);
                });
        }
    },

    methods: {
        closeError() {
            this.showAlert = false;
        },
    },
};
</script>

<style scoped>
header {
    display: flex;
    padding: 20px;
    place-content: center;
}

header h1 {
    font-size: 4.3rem;
    font-weight: 200;
}

.section1 {
    padding: 40px 60px;
    display: flex;
    place-content: center;
    text-align: center;
    margin-top: 92px;
}

.section1 h2 {
    font-size: 1.3rem;
    font-weight: 400;
}

.section2 {
    margin-top: 40px;
    display: flex;
    width: 100vw;
    padding: 15px;
    background-color: rgb(110, 110, 110);
    align-items: flex-end;
    position: relative;
}

.section2 h3 {
    color: #fff;
    margin-left: 15px;
}

.progress {
    width: 100%;
}

footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: 0;
    padding: 10px;
    text-align: center;
    place-items: center;
    background-color: rgb(110, 110, 110);
}

footer a {
    font-size: 0.9rem;
    color: #000;
    padding: 10px;
    margin: 10px 0px;
    border: 1px solid #f4f4f4;
    background-color: #cdcdcd;
    border-radius: 4px;
}

footer a:hover {
    background-color: #fff;
    position: relative;
    top: 5px;
}

footer span img {
    width: 70px;
}

footer span {
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 5px;
}

.loader {
    animation: is-rotating 1s infinite;
    border: 6px solid #e5e5e5;
    border-radius: 50%;
    border-top-color: #51d4db;
    height: 50px;
    width: 50px;
}

@keyframes is-rotating {
    to {
        transform: rotate(1turn);
    }
}

@media (max-width: 319px) {
    .section2 {
        margin-top: 0;
    }

    footer a {
        margin: 5px 0;
        padding: 5px;
    }

    footer span {
        font-size: 12px;
    }
}
</style>
